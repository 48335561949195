import React from "react"
import HTML from "../components/HTML"
import Layout from '../components/layout'

function rawHTMLStaff(props) {
  const { pageContext } = props
  const { pageContent, theme, dealerInfo } = pageContext

  // expose employee info to DOM - if it doesn't exist, set it to undefined
  function exposeEmployeeInfo() {
    if (typeof window !== 'undefined') {
        window.employeeInfo = pageContent.Employees.employeeInfo || undefined;
    }
  };
  exposeEmployeeInfo();

  return (
    <Layout seo={pageContent.SEO}>
      <HTML key={"HTML"} data={pageContent.HTML} theme={theme} dealer={dealerInfo} />
    </Layout>
  )
}
export default rawHTMLStaff